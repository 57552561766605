
import "mind-ar/dist/mindar-image.prod";
import React, { useEffect } from "react";

import "./aframe-tracking-joined-targets.js";
import ImageTrackingViewer from "./image-tracking-viewer";
import joinTargets from "./join-targets";


export default function ArifyView({ arApps }: any) {
    const [mindUrl, setMindUrl] = React.useState("")
    const scene = arApps[0]


    useEffect(() => {
        joinTargets(arApps.map(arApp => arApp.trackingConfig.mindUrl)).then(setMindUrl)


    }, [arApps])

    return <>

        {mindUrl && <ImageTrackingViewer arApps={arApps} mindUrl={mindUrl} />}
        <video
            id='video'
            autoPlay={true}
            playsInline={true}
            muted={true}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                objectFit: "cover",
                zIndex: "-2"

            }}
        ></video>

    </>

}
