import React, { useMemo, useEffect } from 'react';
import { Entity, Scene } from "aframe-react";
import { useAppStore } from '../../../../../stores/AppStore';


const Viewer = ({ arApps: loadedArApps, mindUrl }) => {
    useEffect(() => {
        document.querySelector("#scene").addEventListener("arReady", () => {
            console.log("READ|")
            setTimeout(() => {
                useAppStore.getState().updateIsLoading(false);
            }, 1000)
        })
    }, [])
    const arApps = useMemo(() => {
        return loadedArApps
    }, [])




    const { videoAssets, imageAssets } = useMemo(() => {
        let videoAssets = []
        let imageAssets = []
        for (const arApp of arApps) {
            const items = Object.values(arApp.items)

            videoAssets = videoAssets.concat(items
                .filter(item => item.type === 'videoPlane')
                .map(item => (
                    <video id={`asset-${item.id}`} key={item.id} src={item.src} autoPlay playsInline muted crossOrigin="anonymous" />
                )));

            imageAssets = imageAssets.concat(items
                .filter(item => item.type === 'imagePlane')
                .map(item => (
                    <img id={`asset-${item.id}`} key={item.id} src={item.src} alt="" crossOrigin="anonymous" />
                )));

        }

        return { videoAssets, imageAssets };
    }, [arApps]);


    // Custom RAF callback to log stack trace

    const assets = useMemo(() => {
        return <a-assets>
            {videoAssets}
            {imageAssets}
        </a-assets>
    }, [videoAssets])
    const showStats = useMemo(()=>{
        return arApps.find(app => app.trackingConfig.showPerformanceMetrics)
    }, [arApps])
    const entities = useMemo(() => arApps.map((arApp, index) => {
        const { trackingConfig, items: scene } = arApp;
        const items = Object.values(scene)
        const videoPlanes = items.filter(item => item.type === "videoPlane");
        const object3Ds = items.filter(item => item.type === "3dObject");

        const videoId = `video-source-${arApp.id}`;
        const target = `targetIndex: ${index}; dCutOff: ${parseFloat(trackingConfig.dCutOff)}; filterBeta: ${trackingConfig.filterBeta}; cutOfFrequency: ${trackingConfig.cutOfFrequency}; videoSelector: ${videoId}`
        const lerpProps = trackingConfig.useLinearInterpolation ? { lerpsmooth: `targetId: video-plane-${index}; smoothingFactor: ${parseFloat(trackingConfig.linearInterpolationFactor)}` } : {}
        const imagePlanes = items.filter(item => item.type === "imagePlane");

        return <Entity
            id={`target-${arApp.id}`}
            arify-mindar-image-target={target}
            key={`target-${arApp.id}`}
            primitive="a-entity"
            {...lerpProps}
        >
            {videoPlanes.map(({ scale, position, id, rotation }) => {
                return (
                    <Entity
                        playable={arApp.id}
                        id={id}
                        key={id}
                        geometry={{ primitive: 'plane', height: scale.y, width: scale.x }}
                        material={{ src: `#asset-${id}` }}
                        position={{ x: position.x, y: position.y, z: position.z }}
                        rotation={rotation}
                        type="videoPlane"
                    />
                )
            })}
            {imagePlanes.map(({ position, scale, id, rotation }) => {
                return (
                    <Entity
                        id={id}
                        key={id}
                        geometry={{ primitive: 'plane', height: scale.y, width: scale.x }}
                        material={{ src: `#asset-${id}` }}
                        position={position}
                        rotation={rotation}
                    />
                )
            })}
            {object3Ds.map(({ id, src, position, rotation, scale }) => (
                <a-gltf-model
                    id={id}
                    key={id}
                    playable={arApp.id}
                    src={`url(${src})`}
                    position={`${position.x} ${position.y} ${position.z}`}
                    rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
                    scale={`${scale.x} ${scale.y} ${scale.z}`}
                    animation-mixer="clip: idle; loop: repeat; useRegExp: true"
                    type="3dObject"
                />

                // <a-entity
                //     id={id}
                //     key={id}
                //     playable={arApp.id}
                //     gltf-model={`url(${src})`}
                //     position={`${position.x} ${position.y} ${position.z}`}
                //     rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
                //     scale={`${scale.x} ${scale.y} ${scale.z}`}
                //     animation-mixer="clip: idle; loop: repeat; useRegExp: true"
                //     type="3dObject"
                // ></a-entity>
            ))}
        </Entity>
    }), []);

    return <Scene
        id="scene"
        embedded={true}
        arify-mindar-image={`imageTargetSrc: ${mindUrl}; autoStart: false; uiLoading: no; uiError: no; uiScanning: no;`}
        color-space="sRGB"
        xr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false;"
        stats={showStats}
        renderer="physicallyCorrectLights: true; colorManagement: true"
        arify
    >
        {assets}

        <Entity primitive="a-camera" position="0 0 0"></Entity>
        {entities}
    </Scene>
};

export default Viewer;
