
export default async function joinTargets(targetUrls: string[]): Promise<string> {
    // @ts-expect-error
    const compiler = new window.MINDAR.IMAGE.Compiler();
    let finalData = []
    try {
        for (const fileURL of targetUrls) {
            const content = await fetch(fileURL);
            const buffer = await content.arrayBuffer();
            finalData = finalData.concat(await compiler.importData(buffer));
        }
    } catch (error) {
        throw error
    }
    compiler.data = finalData
    // Export the data from compiler
    const exportedData = compiler.exportData();
    
    // Create a Blob from the exported data
    const blob = new Blob([exportedData], { type: 'application/octet-stream' });

    // Create an object URL from the Blob
    const objectURL = URL.createObjectURL(blob);

    return objectURL;
}