import { CanvasDetectionStates } from '../../../../../model-types/AppModels';

import { useCanvasScannerStore } from "../../../../../stores/CanvasScannerStore";
const { AFRAME } = window;
const { Controller } = window.MINDAR.IMAGE


function traverseAndActivate(entity) {
    // Check if the entity has a type and perform actions based on the type
    const entityType = entity.getAttribute('type');
    switch (entityType) {
        case 'videoPlane':
            const video = entity.querySelector('video');
            if (video && video.paused) {
                video.play().catch(e => console.error("Error playing video: ", e));
            }
            break;
        case '3dObject':
            const animationMixer = entity.components['animation-mixer'];
            if (animationMixer) {
                animationMixer.play(); // Play animation if applicable
            }
            break;
        default:
            // Check for children and recursively activate them
            Array.from(entity.children).forEach(child => traverseAndActivate(child));
            break;
    }
}

// AFRAME.registerComponent('custom-shader', {
//     schema: {
//         edgeSoftness: { type: 'float', default: 0.1 }
//     },

//     init: function () {
//         // Video texture
//         const video = this.el.components.material.material.map.image;
//         // eslint-disable-next-line no-undef
//         const videoTexture = new THREE.VideoTexture(video);

//         // Custom shader material
//         // eslint-disable-next-line no-undef
//         const material = new THREE.ShaderMaterial({
//             uniforms: {
//                 videoTexture: { type: 't', value: videoTexture },
//                 edgeSoftness: { type: 'f', value: this.data.edgeSoftness }
//             },
//             vertexShader: `
//             varying vec2 vUv;
//             void main() {
//                 vUv = uv;
//                 gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
//             }
//         `,
//             fragmentShader: `
//             varying vec2 vUv;
//             uniform sampler2D videoTexture;
//             uniform float edgeSoftness;
//             void main() {
//                 vec4 textureColor = texture2D(videoTexture, vUv);
//                 float alpha = 1.0;
//                 alpha *= smoothstep(0.0, edgeSoftness, vUv.x);
//                 alpha *= smoothstep(0.0, edgeSoftness, 1.0 - vUv.x);
//                 alpha *= smoothstep(0.0, edgeSoftness, vUv.y);
//                 alpha *= smoothstep(0.0, edgeSoftness, 1.0 - vUv.y);
//                 gl_FragColor = vec4(textureColor.rgb, textureColor.a * alpha);
//             }
//         `,
//             transparent: true
//         });

//         // Apply the custom material to the entity
//         this.el.getObject3D('mesh').material = material;
//     }
// });

// console.log(window.MINDAR)
AFRAME.registerComponent("lerpsmooth", {
    init: function () {
        this.target = document.querySelector(`#${this.data.targetId}`); // Ensure this selector matches your target
        console.log("USING smoothing", this.data.smoothingFactor, this.data.targetId)
        // eslint-disable-next-line no-undef
        this.prevPosition = new THREE.Vector3();
        // eslint-disable-next-line no-undef
        this.prevQuaternion = new THREE.Quaternion();
    },
    tick: function () {
        // Ensure the target and element are properly referenced
        if (!this.target || !this.el.object3D) {
            console.warn('lerpsmooth component: Target or element object3D not found.');
            return;
        }

        if (this.el.object3D.visible) {
            this.target.setAttribute('visible', 'true');

            // Lerp position
            if (this.prevPosition) {
                this.target.object3D.position.lerp(this.prevPosition, this.data.smoothingFactor);
            } else {
                // Directly set initial position if there's no previous position
                var initialPosition = this.el.object3D.position.clone();
                this.target.object3D.position.set(initialPosition.x, initialPosition.y, initialPosition.z);
            }

            // Slerp rotation
            if (this.prevQuaternion) {
                this.target.object3D.quaternion.slerp(this.prevQuaternion, this.data.smoothingFactor);
            } else {
                // Directly set initial rotation if there's no previous quaternion
                var initialQuaternion = this.el.object3D.quaternion.clone();
                this.target.object3D.quaternion.set(initialQuaternion.x, initialQuaternion.y, initialQuaternion.z, initialQuaternion.w);
            }

            // Cache current position and rotation for the next tick
            this.prevPosition.copy(this.target.object3D.position);
            this.prevQuaternion.copy(this.target.object3D.quaternion);
        } else {
            this.target.setAttribute('visible', 'false');
            // Consider whether you want to reset prevPosition and prevQuaternion here
            // It depends on the desired behavior when the object becomes visible again
        }
    }
});


// AFRAME.registerComponent('fps30component', {
//     init: function () {
//         this.tick = AFRAME.utils.throttleTick(this.tick, 30, this);
//     },
//     tick: function (time, delta) {
//         // console.log("TIME " + delta);
//     }
// });

AFRAME.registerSystem('arify-mindar-image-system', {
    container: null,
    video: null,
    processingImage: false,
    isTrackingStatesUpdated: false,

    init: function () {
        this.anchorEntities = [];
        this.video = document.querySelector('#video');
    },

    tick: function () {
    },

    setup: function ({ imageTargetSrc, maxTrack, showStats, uiLoading, uiScanning, uiError, missTolerance, warmupTolerance, filterMinCF, filterBeta }) {
        this.imageTargetSrc = imageTargetSrc;
        this.maxTrack = maxTrack;
        this.filterMinCF = filterMinCF;
        this.filterBeta = filterBeta;
        this.missTolerance = missTolerance;
        this.warmupTolerance = warmupTolerance;
        this.showStats = showStats;
    },

    registerAnchor: function (el, targetIndex) {
        this.anchorEntities.push({ el: el, targetIndex: targetIndex });
    },

    start: function () {
        this.container = this.el.sceneEl.parentNode;
        this._startVideo();
    },

    switchTarget: function (targetIndex) {
        this.controller.interestedTargetIndex = targetIndex;
    },

    stop: function () {
        this.pause();
        const tracks = this.video.srcObject.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
        this.video.remove();
        this.controller.dispose();
    },

    pause: function (keepVideo = false) {
        if (!keepVideo) {
            this.video.pause();
        }
        this.controller.stopProcessVideo();
    },

    unpause: function () {
        this.video.play();
        this.controller.processVideo(this.video);
    },

    _startVideo: function () {
        // this.container.appendChild(this.video);

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            // TODO: show unsupported error
            this.el.emit("arError", { error: 'VIDEO_FAIL' });
            return;
        }

        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: 'environment',
                // width: { ideal: 640 * 1.5 }, // or use maxWidth for the maximum supported width
                // height: { ideal: 360 * 1.5 }, // or use maxHeight for the maximum supported height
                // frameRate: { ideal: 120 }
            }
        }).then((stream) => {
            this.video.addEventListener('loadedmetadata', () => {
                console.log("video ready...", this.video.videoWidth, this.video.videoHeight);
                this.video.width = this.video.videoWidth
                this.video.height = this.video.videoHeight;
                this._startAR();
            });
            this.video.srcObject = stream;
        }).catch((err) => {
            console.log("getUserMedia error", err);
            this.el.emit("arError", { error: 'VIDEO_FAIL' });
        });
    },

    _startAR: async function () {
        this.controller = new Controller({
            inputWidth: this.video.videoWidth,
            inputHeight: this.video.videoHeight,
            maxTrack: this.maxTrack,
            filterMinCF: this.filterMinCF,
            filterBeta: this.filterBeta,
            missTolerance: this.missTolerance,
            warmupTolerance: this.warmupTolerance,
            onUpdate: (data) => {
                if (data.type === 'updateMatrix') {
                    const { targetIndex, worldMatrix } = data;

                    for (let i = 0; i < this.anchorEntities.length; i++) {
                        if (this.anchorEntities[i].targetIndex === targetIndex) {
                            this.anchorEntities[i].el.updateWorldMatrix(worldMatrix);
                        }
                    }
                }
            }
        });

        this._resize();
        window.addEventListener('resize', this._resize.bind(this));
        const { dimensions: imageTargetDimensions } = await this.controller.addImageTargets(this.imageTargetSrc);

        for (let i = 0; i < this.anchorEntities.length; i++) {
            const { el, targetIndex } = this.anchorEntities[i];
            if (targetIndex < imageTargetDimensions.length) {
                el.setupMarker(imageTargetDimensions[targetIndex]);
                console.log(imageTargetDimensions[targetIndex])
            }
        }
        await this.controller.dummyRun(this.video);

        this.controller.processVideo(this.video);

        for (let i = 0; i < this.controller.trackingStates.length; i++) {
            this.controller.trackingStates[i].filter.beta = parseFloat(this.anchorEntities[i].el.data.filterBeta)
            this.controller.trackingStates[i].filter.minCutOff = parseFloat(this.anchorEntities[i].el.data.cutOfFrequency)
            this.controller.trackingStates[i].filter.dCutOff = parseFloat(this.anchorEntities[i].el.data.dCutOff);
            //     const videoElement = document.querySelector(`#${this.anchorEntities[i].el.data.videoSelector}`);

            this.anchorEntities[i].el.el.addEventListener("targetFound", () => {
                useCanvasScannerStore.getState().setScanningState(CanvasDetectionStates.FOUND)
                const [_, sceneId] = this.anchorEntities[i].el.el.id.split("-");
                const playables = document.querySelectorAll(`[playable="${sceneId}"]`)
                for (const playable of playables) {
                    const type = playable.getAttribute("type")
                    if (type === "videoPlane") {
                        playable.components.material.data.src.play()
                    }
                    if (type === "3dObject") {


                        // Ensure the animation-mixer component is present and configured
                        playable.setAttribute('animation-mixer', 'timeScale: 1.0;');
                        playable.setAttribute('animation-mixer', 'clip: c;');

                        // Use a timeout to give A-Frame time to initialize the component
                        const model = document.getElementById(playable.id)
                        const animationMixer = model.components['animation-mixer'];
                        if (animationMixer) {
                            // Play the animation
                            animationMixer.play();

                            // Optionally handle animation events

                        } else {
                            console.warn("Animation mixer component not found on model", playable);
                        }

                    }
                }
            });

            this.anchorEntities[i].el.el.addEventListener("targetLost", () => {
                useCanvasScannerStore.getState().setScanningState(CanvasDetectionStates.SEARCHING)

                const [_, sceneId] = this.anchorEntities[i].el.el.id.split("-");
                const playables = document.querySelectorAll(`[playable="${sceneId}"]`)
                for (const playable of playables) {
                    const type = playable.getAttribute("type")
                    if (type === "videoPlane") {
                        console.log(playable.components.material)
                        playable.components.material.data.src.pause()
                        playable.components.material.data.src.currentTime = 0;
                    }
                    if (type === "3dObject") {


                        // Ensure the animation-mixer component is present and configured
                        playable.setAttribute('animation-mixer', 'timeScale: 1.0;');
                        playable.setAttribute('animation-mixer', 'clip: c;');

                        // Use a timeout to give A-Frame time to initialize the component
                        const model = document.getElementById(playable.id)
                        const animationMixer = model.components['animation-mixer'];
                        if (animationMixer) {
                            // Play the animation
                            animationMixer.pause();

                            // Optionally handle animation events

                        } else {
                            console.warn("Animation mixer component not found on model", playable);
                        }

                    }
                }
            });
            //     // Resize video
            //     const [width, height] = imageTargetDimensions[i]
            //     const ratio = height / width
            //     document.querySelector(`#video-plane-${i}`).setAttribute("height", ratio)

            //     this.anchorEntities[i].el.el.addEventListener("targetLost", () => {
            //         try {
            //             videoElement.pause()
            //         } catch (e) {
            //             console.error(e)
            //         }

            //     })
        }
        console.log(this.controller.trackingStates)
        this.el.emit("arReady");
    },

    _resize: function () {

        let vw, vh; // display css width, height
        const videoRatio = this.video.videoWidth / this.video.videoHeight;
        const containerRatio = this.container.clientWidth / this.container.clientHeight;
        if (videoRatio > containerRatio) {
            vh = this.container.clientHeight;
            vw = vh * videoRatio;
        } else {
            vw = this.container.clientWidth;
            vh = vw / videoRatio;
        }


        const proj = this.controller.getProjectionMatrix();
        const fov = 2 * Math.atan(1 / proj[5] / vh * this.container.clientHeight) * 180 / Math.PI; // vertical fov
        const near = proj[14] / (proj[10] - 1.0);
        const far = proj[14] / (proj[10] + 1.0);
        const newAspect = this.container.clientWidth / this.container.clientHeight;
        const cameraEle = this.container.getElementsByTagName("a-camera")[0];
        const camera = cameraEle.getObject3D('camera');
        camera.fov = fov;
        camera.aspect = newAspect;
        camera.near = near;
        camera.far = far;
        camera.updateProjectionMatrix();
        //const newCam = new AFRAME.THREE.PerspectiveCamera(fov, newRatio, near, far);
        //camera.getObject3D('camera').projectionMatrix = newCam.projectionMatrix;

        this.video.style.top = (-(vh - this.container.clientHeight) / 2) + "px";
        this.video.style.left = (-(vw - this.container.clientWidth) / 2) + "px";
        this.video.style.width = vw + "px";
        this.video.style.height = vh + "px";
    }
});

AFRAME.registerComponent('arify-mindar-image', {
    dependencies: ['arify-mindar-image-system'],

    schema: {
        imageTargetSrc: { type: 'string' },
        maxTrack: { type: 'int', default: 1 },
        filterMinCF: { type: 'number', default: -1 },
        filterBeta: { type: 'number', default: -1 },
        missTolerance: { type: 'int', default: -1 },
        warmupTolerance: { type: 'int', default: -1 },
        showStats: { type: 'boolean', default: false },
        autoStart: { type: 'boolean', default: true },
        uiLoading: { type: 'string', default: 'yes' },
        uiScanning: { type: 'string', default: 'yes' },
        uiError: { type: 'string', default: 'yes' },

    },

    init: function () {
        const arSystem = this.el.sceneEl.systems['arify-mindar-image-system'];
        // console.dir(JSON.parse(this.data.urls));

        arSystem.setup({
            imageTargetSrc: this.data.imageTargetSrc,
            maxTrack: this.data.maxTrack,
            filterMinCF: this.data.filterMinCF === -1 ? null : this.data.filterMinCF,
            filterBeta: this.data.filterBeta === -1 ? null : this.data.filterBeta,
            missTolerance: this.data.missTolerance === -1 ? null : this.data.missTolerance,
            warmupTolerance: this.data.warmupTolerance === -1 ? null : this.data.warmupTolerance,
            showStats: this.data.showStats,
            uiLoading: this.data.uiLoading,
            uiScanning: this.data.uiScanning,
            uiError: this.data.uiError,
        });
        if (this.data.autoStart) {
            this.el.sceneEl.addEventListener('renderstart', () => {
                arSystem.start();
            });
        }
    },
    remove: function () {
        const arSystem = this.el.sceneEl.systems['arify-mindar-image-system'];
        arSystem.stop();
    }
});

AFRAME.registerComponent('arify-mindar-image-target', {
    dependencies: ['arify-mindar-image-system'],

    schema: {
        targetIndex: { type: 'number' },
        filterBeta: { type: 'number' },
        cutOfFrequency: { type: 'number' },
        dCutOff: { type: 'number' },
        videoSelector: { type: 'string' },
    },

    postMatrix: null, // rescale the anchor to make width of 1 unit = physical width of card

    init: function () {

        this.el.sceneEl.systems['arify-mindar-image-system'].registerAnchor(this, this.data.targetIndex);

        this.invisibleMatrix = new AFRAME.THREE.Matrix4().set(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
        this.el.object3D.visible = false;
        this.el.object3D.matrixAutoUpdate = false;
        this.el.object3D.matrix = this.invisibleMatrix;
        this.tempMatrix = new AFRAME.THREE.Matrix4();

        // Initialize previous position and quaternion for interpolation
        // eslint-disable-next-line no-undef
        this.prevPosition = new THREE.Vector3();
        // eslint-disable-next-line no-undef
        this.prevQuaternion = new THREE.Quaternion();
        // this.adjustUpdateFrequency(120)
    },

    // tick: function (time) {
    //     if (time - this.lastUpdateTime < this.updateInterval) {
    //         this.shouldUpdate = false;
    //         return; // Skip the update if the interval hasn't passed
    //     }

    //     this.lastUpdateTime = time; // Update the last update time
    //     this.shouldUpdate = true;
    // },

    adjustUpdateFrequency: function (fps) {
        // Adjust update frequency based on FPS (this is a simple example)
        if (fps < 30) {
            this.updateInterval = 200; // Less frequent updates for lower FPS
        } else if (fps < 60) {
            this.updateInterval = 100; // Moderate update frequency
        } else {
            this.updateInterval = 50; // More frequent updates for high FPS
        }
    },
    setupMarker: function ([markerWidth, markerHeight]) {
        this.markerPosition = new AFRAME.THREE.Vector3(markerWidth / 2, markerWidth / 2 + (markerHeight - markerWidth) / 2, 0);
        this.markerScale = new AFRAME.THREE.Vector3(markerWidth, markerWidth, markerWidth);
        this.markerQuaternion = new AFRAME.THREE.Quaternion();
        this.postMatrix = new AFRAME.THREE.Matrix4();
        this.postMatrix.compose(this.markerPosition, this.markerQuaternion, this.markerScale);
    },

    updateWorldMatrix: function (worldMatrix) {
        if (!this.el.object3D.visible && !!worldMatrix) {
            this.el.emit("targetFound");
        } else if (this.el.object3D.visible && worldMatrix === null) {
            this.el.emit("targetLost");
        }

        this.el.object3D.visible = !!worldMatrix;
        if (!worldMatrix) {
            return;
        }
        // Reuse `this.tempMatrix` instead of creating a new matrix every time
        this.tempMatrix.fromArray(worldMatrix).multiply(this.postMatrix);
        this.el.object3D.matrix = this.tempMatrix;
        this.el.object3D.visible = true;
    }
});

AFRAME.registerComponent('arify', {

    init: function () {

        this.el.sceneEl.addEventListener("arError", (event) => {
            console.log("MindAR failed to start")
        });

        this.el.sceneEl.addEventListener("arReady", () => {
            console.log("READY")
        });

        this.el.sceneEl.addEventListener('renderstart', () => {
            this.el.sceneEl.systems['arify-mindar-image-system'].start(); // Now safe to call start
        })

    },
    tick: function () {

    }
})