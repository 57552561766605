
import "mind-ar/dist/mindar-face-aframe.prod.js";
import React, { useEffect } from "react";

import FaceTrackingViewer from "./face-tracking-viewer";


export default function ArifyView({ arApps }: any) {

    return <>

        <FaceTrackingViewer arApps={arApps} />
        {/* <video
            id='video'
            autoPlay={true}
            playsInline={true}
            muted={true}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                objectFit: "cover",
                zIndex: "-2"

            }}
        ></video> */}

    </>

}
