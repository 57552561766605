
import { Box } from '@chakra-ui/react';
import "aframe";
import "aframe-extras";
import React, { useEffect } from "react";
import Logo from '../../../../components/Logo';
import ScanningAnimation from "../../../../components/ScanningAnimation";
import ArViewerControls from "./ar-viewer-controls";
import ImageTrackingViewer from "./image-tracking";
import FaceTrackingViewer from "./face-tracking";
import MediaCapture from "./media-capture";
import { SceneType } from '@prisma/client';

const getViewerForScene = (sceneType: SceneType) => {
    if (sceneType === SceneType.IMAGE_TRACKING) {
        return ImageTrackingViewer
    }
    if (sceneType === SceneType.FACE_TRACKING) {
        return FaceTrackingViewer
    }
}
export default function ArifyView({ arApps }: any) {
    const scene = arApps[0]
    const Viewer = getViewerForScene(scene.type)

    return <>
        <ScanningAnimation />
        <Box position='fixed' top='0' left='0' right='0' display='flex' justifyContent='center' alignItems='center' height='50px'>
            <Logo />
        </Box>
        <Box style={{
            // width: window.innerWidth + "px",
            // height: window.innerHeight + "px",
            width: "100vw",
            height: "100vh",
            position: "relative",
            overflow: "hidden",
        }}>
            <Viewer arApps={arApps}/>
            <MediaCapture />
            <ArViewerControls />

        </Box>
    </>

}
